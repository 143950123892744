import React from 'react';
import {
  EditableInlineText,
  useContentElementConfigurationUpdate
} from 'pageflow-scrolled/frontend';

import styles from './InfoGrid.module.css';

const icons = require.context('../../images/icons', false, /\.svg$/);

export function InfoGrid({configuration}) {
  const updateConfiguration = useContentElementConfigurationUpdate();

  return (
    <div className={styles.grid}>
      {[...Array(8)].map((_, i) => {
        return (
          <Cell key={i}
                title={configuration[`title${i}`]}
                text={configuration[`text${i}`]}
                icon={configuration[`icon${i}`]}
                onTitleChange={title => updateConfiguration({
                  [`title${i}`]: title
                })}
                onTextChange={text => updateConfiguration({
                  [`text${i}`]: text
                })} />
        );
      })}
    </div>
  );
}

function Cell({title, text, icon = './lightbulb.svg', onTitleChange, onTextChange}) {
  return (
    <div className={styles.cell}>
      <img className={styles.icon}
           src={icons(icon)}
           alt=""/>
      <h4 className={styles.title}>
        <EditableInlineText value={title}
                            onChange={onTitleChange}
                            placeholder="Title" />
      </h4>
      <div className={styles.text}>
        <EditableInlineText value={text}
                            onChange={onTextChange}
                            placeholder="Text" />
      </div>
    </div>
  );
}
