import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import styles from './Highlights.module.css';

import {
  EditableInlineText,
  FitViewport,
  Text,
  VideoPlayer,
  useContentElementConfigurationUpdate,
  useContentElementLifecycle,
  useFile,
  usePlayerState
} from 'pageflow-scrolled/frontend';

export function Highlights({configuration}) {
  const updateConfiguration = useContentElementConfigurationUpdate();

  const videoFile = useFile({collectionName: 'videoFiles',
                             permaId: configuration.videoId});

  const [playerState, playerActions] = usePlayerState();

  const activeItem = [4, 3, 2, 1].find((i) =>
    playerState.currentTime > parseFloat(configuration[`itemStartTime${i}`])
  ) || 0;

  const {shouldLoad, shouldPrepare} = useContentElementLifecycle({
    onVisible() {
      playerActions.play();
    },

    onInvisible() {
      playerActions.fadeOutAndPause(400);
    },
  });

  return (
    <div className={styles.outer}>
      <ul className={styles.list}>
        {[...Array(5)].map((_, i) => (
          <li key={i}
              className={classNames(styles.item,
                                    {[styles.active]: i === activeItem})}>
            <Text scaleCategory="body">
              <EditableInlineText value={configuration[`item${i}`]}
                                  onChange={value => updateConfiguration({
                                    [`item${i}`]: value
                                  })}
                                  placeholder="Text" />
            </Text>
          </li>
        ))}
      </ul>

      <div className={styles.video}>
        <FitViewport file={videoFile}
                     aspectRatio={videoFile ?
                                  undefined : fallbackAspectRatio}>
          <FitViewport.Content>
            <VideoPlayer load={shouldPrepare ? 'auto' :
                               shouldLoad ? 'poster' :
                               'none'}
                         loop={true}
                         playerState={playerState}
                         playerActions={playerActions}
                         videoFile={videoFile}
                         playsInline={true} />
          </FitViewport.Content>
        </FitViewport>
      </div>
    </div>
  );
}

const fallbackAspectRatio = 0.5625;
