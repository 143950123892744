import React from 'react';
import classNames from 'classnames';

import {useFile} from 'pageflow-scrolled/frontend';

import styles from './LogoBar.module.css';

export function LogoBar({configuration}) {
  return (
    <div className={styles.outer}>
      {renderLogos(configuration)}
      {renderLogos(configuration)}
    </div>
  );
}

function renderLogos(configuration) {
  const images = configuration.images || [];

  return (
    <div className={classNames(styles.inner,
                               styles[`style-${configuration.style}`])}>
      {images.map(id => <Logo imageId={id} key={id} />)}
    </div>
  );
}

function Logo({imageId}) {
  const imageFile = useFile({collectionName: 'imageFiles', permaId: imageId});

  if (!imageFile) {
    return null;
  }

  const src = imageFile.urls.thumbnail
                       .replace(/v\d\/thumbnail/, 'original')
                       .replace(/JPG$/, 'png');

  return (
    <img src={src}
         alt={imageFile.configuration.alt}
         width={imageFile.width}
         height={imageFile.height}/>
  );
}
