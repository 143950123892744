import React from 'react';
import classNames from 'classnames';
import {
  EditableInlineText,
  useContentElementConfigurationUpdate,
  useContentElementEditorState
} from 'pageflow-scrolled/frontend';

import styles from './Cta.module.css';

export function Cta({configuration, sectionProps}) {
  const updateConfiguration = useContentElementConfigurationUpdate();
  const {isEditable} = useContentElementEditorState();

  return (
    <div className={classNames(styles.wrapper,
                               styles[`layout-${sectionProps.layout}`])}>
      <a className={classNames(styles.cta, styles[configuration.color])}
         href={configuration.url}
         target={configuration.openInNewTab ? '_blank' : null}
         rel={configuration.openInNewTab ? 'noopener noreferrer' : null}
         data-event={configuration.analyticsEvent}
         data-event-props={JSON.stringify({id: configuration.analyticsId})}
         onClick={event => { if (isEditable) event.preventDefault() }}>
        <EditableInlineText value={configuration.text}
                            onChange={text => updateConfiguration({text})}
                            placeholder="Text" />
      </a>
    </div>
  );
}
