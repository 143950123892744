import React from 'react';
import classNames from 'classnames';

import {useContentElementEditorState} from 'pageflow-scrolled/frontend';

import styles from './Ornament.module.css';

const images = require.context('./images', false, /\.svg$/);

export function Ornament({configuration}) {
  const {isSelected, isEditable} = useContentElementEditorState();

  return (
    <div className={classNames(styles.wrapper,
                               {[styles.hideOnPhone]: configuration.hideOnPhone},
                               styles[`alignment-${configuration.alignment}`],
                               styles[`verticalAlignment-${configuration.verticalAlignment}`])}>
      {(isEditable && !isSelected) && <div className={styles.clickHandle} />}
      <div className={styles.clip}>
        <img src={images(configuration.image)}
             alt=""
             style={configuration.alignment === 'left' ?
                    {marginLeft: `${configuration.margin}%`} :
                    configuration.alignment === 'right' ?
                    {marginRight: `${configuration.margin}%`} :
                    {}} />
      </div>
    </div>
  );
}
