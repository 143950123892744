import React from 'react';

import {
  useAdditionalSeedData,
  useContentElementEditorState,
  useLocale
} from 'pageflow-scrolled/frontend';

import {Pagination} from './Pagination';

import styles from './BlogPosts.module.css';

export function BlogPosts({configuration}) {
  const {posts, currentPage, totalPages} = useAdditionalSeedData('blog');

  return (
    <div>
      <div>
        {posts.map(post => <BlogPost post={post} />)}
      </div>
      <Pagination currentPage={currentPage}
                  totalPages={totalPages} />
    </div>
  );
}

function BlogPost({post}) {
  const t = translations[useLocale()];
  const {isEditable} = useContentElementEditorState();

  const linkProps = isEditable ?
                    {target: '_blank', rel: 'noopener noreferrer'} :
                    {};

  return (
    <div className={styles.blogPost}>
      <p className={styles.publishedAt}>{post.publishedAt}</p>
      <h3 className={styles.heading}>
        <a href={post.url} {...linkProps}>{post.title}</a>
      </h3>
      <p className={styles.summary}>{post.summary}</p>
      <a href={post.url} className={styles.readMore} {...linkProps} >
        {t.readMore}
      </a>
    </div>
  );
}

const translations = {
  en: {
    readMore: 'Read more'
  },
  de: {
    readMore: 'Mehr lesen'
  },
};
