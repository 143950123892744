import React, {useState} from 'react';
import classNames from 'classnames';
import {
  EditableInlineText,
  useContentElementConfigurationUpdate,
  useContentElementEditorState
} from 'pageflow-scrolled/frontend';

import styles from './Faq.module.css';

import chevronRight from '../../images/icons/chevronRight.svg';
import chevronDown from '../../images/icons/chevronDown.svg';

const icons = require.context('../../images/icons', false, /\.svg$/);

export function Faq({configuration}) {
  const updateConfiguration = useContentElementConfigurationUpdate();
  const {isSelected} = useContentElementEditorState();
  const [isExpanded, setIsExpanded] = useState(false);

  const displayExpanded = isExpanded || isSelected;

  return (
    <div className={classNames(styles.wrapper,
                               {[styles.expanded]: displayExpanded})}>
      <button className={styles.question}
              data-event={isExpanded ? null : 'Faq: Expand'}
              data-event-props={JSON.stringify({id: configuration.analyticsId})}
              onClick={() => setIsExpanded(!isExpanded)}>
        <img className={styles.icon}
             src={icons(configuration.icon)}
             alt=""/>
        <span className={styles.questionText}>
          <EditableInlineText value={configuration.question}
                              onChange={question => updateConfiguration({question})}
                              placeholder="Text" />
        </span>
        <img src={displayExpanded ? chevronDown : chevronRight}
             alt="" />
      </button>
      <div className={styles.answer}>
        <EditableInlineText value={configuration.answer}
                            onChange={answer => updateConfiguration({answer})}
                            placeholder="Text"/>
      </div>
    </div>
  );
}
