import React from 'react';

import {
  useAdditionalSeedData
} from 'pageflow-scrolled/frontend';

import styles from './LegacyBlogPost.module.css';

export function LegacyBlogPost() {
  const {html} = useAdditionalSeedData('legacyBlogPost');

  return (
    <div className={styles.wrapper}
         dangerouslySetInnerHTML={{__html: html}} />
  );
}
