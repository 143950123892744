import React from 'react';

import {
  useLocale
} from 'pageflow-scrolled/frontend';

import styles from './Pagination.module.css';

import chevronLeft from '../../images/icons/chevronLeft.svg';
import chevronRight from '../../images/icons/chevronRight.svg';

export function Pagination({currentPage, totalPages}) {
  const t = translations[useLocale()];

  return (
    <div className={styles.wrapper}>
      {currentPage > 1 &&
       <a className={styles.chevron}
          href={`?page=${currentPage - 1}`}>
         <img alt={t.prev} src={chevronLeft} />
       </a>}
      {[...Array(totalPages)].map((_, i) => {
        const page = i + 1;

        if (page === currentPage) {
          return (
            <span className={styles.item} key={page}>
              {page}
            </span>
          );
        }
        else {
          return <a className={styles.item}
                    key={page}
                    href={`?page=${page}`}>{page}</a>;
        }
      })}
      {currentPage < totalPages &&
       <a className={styles.chevron}
          href={`?page=${currentPage + 1}`}>
         <img alt={t.next} src={chevronRight} />
       </a>}
    </div>
  )
}

const translations = {
  en: {
    prev: 'Previous page',
    next: 'Next page'
  },
  de: {
    prev: 'Vorherige Seite',
    next: 'Nächste Seite'
  },
};
